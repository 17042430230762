<template>
    <div>
        <h1>New Supplier</h1>
        <form @submit.prevent="newSupplier">
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Supplier</label>
                    <input type="text" v-model="formFields.supplier_name.val" class="form-control" placeholder="New">
                </div>
            </div>
            <input type="submit" class="btn btn-default" value="Save">
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js'
    import helpers from "@/store/helpers";
    export default {
        name: "New",
        data() {
            return {
                state: store.state,
                formFields: {
                    supplier_name: { val: '', qsField: '[supplier_name]' }
                }
            }
        },
        created() {
            store.clearObjectData();
        },
        methods: {
            newSupplier: function() {
              store.api('save_new_supplier', helpers.getQSPairs(this.formFields))
                  .then(result => {
                        this.$router.push({path: `/${this.state.nav.base}/${result.supplier_id}`});
                      }
                  );
            }
        },
    }
</script>

<style scoped>

</style>