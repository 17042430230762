import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=5bcdcc1f&scoped=true&"
import script from "./Container.vue?vue&type=script&lang=js&"
export * from "./Container.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bcdcc1f",
  null
  
)

export default component.exports