import paymentCache from "@/cache/payment.cache";

export const supplierStore = {
    state: {
        name: 'supplier',
        title: 'Supplier',
        titlePlural: 'Suppliers',
        nav: {
            base: 'supplier',
            newView: true,
            views: [
                { view: 'details', label: 'Details', }
            ]
        },
        search: {
            endPoint: 'suppliers',
            formFields: {
                vendorName: {
                    val: '',
                    qsField: '[supplier_name]',
                    label: 'Supplier',
                    component: 'SearchTextInput'
                }
            },
            resultFields: {
                supplier_id: {
                    header: 'ID',
                    link: 'supplier'
                },
                supplier_name: {
                    header: 'Supplier'
                },
                corpay_url: {
                    header: 'Corpay',
                    link: 'external',
                }
            }
        },
        object: {
            fields: [
                {
                    name: 'supplier_id',
                    label: 'ID',
                    component: 'DetailsStatic'
                },
                {
                    name: 'name',
                    label: 'Supplier Name',
                    component: 'DetailsText',
                },
                {
                    name: 'term_id',
                    label: 'Terms',
                    component: 'DetailsSelect',
                    options: paymentCache.TERMS,
                },
                {
                    name: 'status',
                    label: 'Status',
                    component: 'DetailsSelect',
                    options: [
                        ['NEW', 'NEW'],
                        ['PENDING', 'PENDING'],
                        ['ACTIVE', 'ACTIVE'],
                        ['INACTIVE', 'INACTIVE'],
                        ['REJECTED', 'REJECTED']
                    ]
                },
                {
                    name: 'id',
                    label: 'Corpay ID',
                    component: 'DetailsStatic'
                },
                {
                    name: 'legal_name',
                    label: 'Legal Name (Edit in Corpay)',
                    component: 'DetailsStatic'
                }
            ]
        }
    }
}
