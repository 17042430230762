<template>
    <div>
        <div v-if="id">
            <h1>{{ state.object.data.name }}</h1>
            <Details :data="state.object.data" v-show="isCurrentView('details')"/>
            <div class=""><h3>Contact <span class="pull-right glyphicon glyphicon-plus text-primary pointer" @click="openContactModal" style="margin-right: 10px"></span></h3></div>
            <div class="table-responsive" id="dashboard_table">
                <table class='table'>
                    <thead>
                    <tr>
                        <th></th>
                        <th>Reference IDs</th>
                        <th>Type</th>
                        <th>CDI Location</th>
                        <th>Email</th>
                        <th>Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="contact in contacts" v-bind:key="contact.supplier_address_id">
                        <td>
                            <div class="text-center" v-show="contact && contact.is_default == 'true'"><span class="glyphicon glyphicon-star-empty"></span> Default <br /></div>
                            <div class="text-center">
                                <span class="glyphicon glyphicon-pencil cdi_link" @click="openContactModal(contact)"></span>&nbsp;
                                <span class="glyphicon glyphicon-trash cdi_link" @click="deleteConfirm(contact.supplier_address_id)"></span>
                            </div>

                        </td>
                        <td>
                            <strong>CDI:</strong> {{ contact.supplier_address_id }} <br />
                            <strong>Corpay:</strong> {{ contact.corpay_id }}
                        </td>
                        <td>{{ contact.address_type }}</td>
                        <td>{{ cache.cdiLocationCache.CDI_LOCATIONS_KV[contact.cdi_location_id] }}</td>
                        <td>{{ contact.email }}</td>
                        <td>
                            <span v-show="contact.attention">Attn: {{ contact.attention }} </span><br />
                            <span v-show="contact.address1">{{ contact.address1 }} <br /></span>
                            <span v-show="contact.address2">{{ contact.address2 }} <br /></span>
                            <span v-show="contact.address3">{{ contact.address3 }} <br /></span>
                            {{ contact.city }}, {{ contact.state }} {{ contact.zipcode }} {{ contact.country }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <Modal heading="Add Contact" :classes="'wide80 tall80'" v-if='addContactModal' @close="closeAddContact">
                <template v-slot:body>
                    <div class="panel panel-default spacing_top">
                        <div class="row panel-body">
                            <div class="col-sm-6 col-xs-12 form-group">
                                <label>Location</label>
                                <select class="form-control" v-model="formFields.cdi_location_id.val">
                                    <option value="0">No set location</option>
                                    <option v-for="[cdiLocationId, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS"
                                            :key="cdiLocationId" :value="cdiLocationId">{{ cdiLocation }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-xs-12 form-group">
                                <label>Address Type</label>
                                <select class="form-control" v-model="formFields.address_type.val">
                                    <option value="Billing">Billing</option>
                                    <option value="Mailing">Mailing</option>
                                    <option value="Shipping">Shipping</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-xs-12 form-group">
                                <label>Email</label>
                                <input type="text" v-model="formFields.email.val" class="form-control">
                            </div>
                            <div class="col-xs-12 form-group">
                                <label>Attention</label>
                                <input type="text" v-model="formFields.attention.val" class="form-control">
                            </div>
                            <div class="col-xs-12 form-group">
                                <label>Address 1</label>
                                <input type="text" v-model="formFields.address1.val" class="form-control">
                            </div>
                            <div class="col-xs-12 form-group">
                                <label>Address 2</label>
                                <input type="text" v-model="formFields.address2.val" class="form-control">
                            </div>
                            <div class="col-xs-12 form-group">
                                <label>Address 3</label>
                                <input type="text" v-model="formFields.address3.val" class="form-control">
                            </div>
                            <div class="col-sm-4 col-xs-12 form-group">
                                <label>City</label>
                                <input type="text" v-model="formFields.city.val" class="form-control">
                            </div>
                            <div class="col-sm-4 col-xs-12 form-group">
                                <label>State</label>
                                <select class="form-control" v-model="formFields.state.val">
                                    <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr"
                                            :value="stateAbbr">{{ state }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-4 col-xs-12 form-group">
                                <label>Country</label>
                                <select class="form-control" v-model="formFields.country.val">
                                    <option value="USA">USA</option>
                                    <option value="CAN">Canada</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-xs-12 form-group">
                                <label>Zip</label>
                                <input type="text" v-model="formFields.zipcode.val" class="form-control">
                            </div>
                            <div class="col-sm-6 col-xs-12 form-group">
                                <label>Corpay Vendor ID</label>
                                <input type="text" disabled v-model="formFields.addressable_id.val" class="form-control">
                            </div>
                            <div class="col-sm-6 col-xs-12 form-group">
                                <label>Addressable Type</label>
                                <input type="text" disabled v-model="formFields.addressable_type.val" class="form-control">
                            </div>
                            <div class="col-xs-12 form-grid">
                                <label><input type="checkbox" v-model="formFields.is_default.val" :true-value="'true'" :false-value="'false'"> Default</label>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                    <button class='btn btn-primary' @click='saveAddContact'>Save</button>
                    <button class='btn btn-default' @click='closeAddContact'>Cancel</button>
                </template>
            </Modal>
            <ConfirmBox
                v-if="confirmBox"
                :heading="'Delete Contact?'"
                :body="'Are you sure you want to delete this contact?'"
                @close="deleteContact"
            />
        </div>

        <New v-else-if="isCurrentView('new')"/>

        <Search v-else :showMoreButton="false"/>
    </div>
</template>

<script>
import {store} from "@/store/BusinessObject.store.js";
import {supplierStore} from "./Supplier.store.js";
import Search from "@/components/businessObjects/utils/Search";
import Details from "@/components/businessObjects/utils/Details";
import New from "@/components/businessObjects/supplier/New";
import ConfirmBox from "@/components/utils/ConfirmBox.vue";
import Modal from "@/components/utils/Modal.vue";
import stateCache from "@/cache/state.cache"
import cdiLocationCache from "@/cache/cdiLocation.cache";
import helpers from "@/store/helpers";

export default {
    name: "Container.vue",
    data() {
        return {
            state: store.state,
            supplierState: supplierStore.state,
            contacts: [],
            confirmBox: false,
            addContactModal: false,
            formFields: {
                supplier_address_id: {val: 0, qsField: '[supplier_address_id]'},
                address_type: {val: 0, qsField: '[address_type]'},
                cdi_location_id: {val: 0, qsField: '[cdi_location_id]'},
                email: {val: '', qsField: '[email]'},
                address1: {val: '', qsField: '[address1]'},
                address2: {val: '', qsField: '[address2]'},
                address3: {val: '', qsField: '[address3]'},
                city: {val: '', qsField: '[city]'},
                country: {val: '', qsField: '[country]'},
                state: {val: '', qsField: '[state]'},
                zipcode: {val: '', qsField: '[zipcode]'},
                addressable_type: {val: 'Vendor', qsField: '[addressable_type]'},
                attention: {val: '', qsField: '[attention]'},
                addressable_id: {val: store.state.object.data.id, qsField: '[addressable_id]'},
                is_default: {val: 0, qsField: '[is_default]'}
            },
            activeAddressEdit: null,
            cache: {
                stateCache,
                cdiLocationCache
            }
        }
    },
    props: ["id"],
    components: {
        Modal,
        ConfirmBox,
        New,
        Search,
        Details
    },
    created() {
        store.initialize(this.supplierState);
        this.load();
    },
    methods: {
        load: function () {
            if (this.id) {
                store.load(this.id)
                    .then(() => this.$appStore.setTitle([this.state.object.data.supplier_name, this.state.titlePlural]));
            }
            if (this.id) {
                let options = {'params[supplier_id]': this.id};
                store.apiGeneric('supplier/get_contacts', options).then(results => this.contacts = results);
            }
        },
        openContactModal: function (obj = null) {
            if (obj) {
                this.populateAddressEdit(obj);
            }
            this.addContactModal = true;
        },
        populateAddressEdit: function(obj) {
            Object.entries(obj).forEach(([key, value]) => {
                if (this.formFields.hasOwnProperty(key)) {
                    this.formFields[key].val = value;
                }
            });
        },
        closeAddContact: function () {
            this.addContactModal = false;
            this.clearForm();
        },

        clearForm: function () {
            const defaultState = {
                supplier_address_id: {val: 0, qsField: '[supplier_address_id]'},
                address_type: {val: 0, qsField: '[address_type]'},
                cdi_location_id: {val: 0, qsField: '[cdi_location_id]'},
                email: {val: '', qsField: '[email]'},
                address1: {val: '', qsField: '[address1]'},
                address2: {val: '', qsField: '[address2]'},
                address3: {val: '', qsField: '[address3]'},
                city: {val: '', qsField: '[city]'},
                country: {val: '', qsField: '[country]'},
                state: {val: '', qsField: '[state]'},
                zipcode: {val: '', qsField: '[zipcode]'},
                addressable_type: {val: 'Vendor', qsField: '[addressable_type]'},
                attention: {val: '', qsField: '[attention]'},
                addressable_id: {val: store.state.object.data.id, qsField: '[addressable_id]'},
                is_default: {val: 0, qsField: '[is_default]'}
            };

            for (const name in this.formFields) {
                this.formFields[name].val = defaultState[name].val;
            }
        },

        saveAddContact: function () {
            let scope = this;
            store.api('save_supplier_address', helpers.getQSPairs(this.formFields), 'object.data')
                .then(this.load)
                .then(function() {
                    scope.closeAddContact();
                });
        },
        isCurrentView: function (view) {
            return view === this.state.currentView;
        },
        deleteConfirm: function (supplier_contact_id) {
            this.deleteID = supplier_contact_id;
            this.confirmBox = true;
        },
        deleteContact: function (confirm) {
            if (confirm) {
                let options = {
                    'params[supplier_address_id]': this.deleteID,
                };
                store.apiWithObjectLoad('delete_contact', options).then(this.load)
            }
            this.confirmBox = false;
            this.deleteID = 0;
        },
    },
    computed: {
        currentView: function () {
            return this.state.currentView;
        },
        addressableID: function() {
            return this.state.object.data.id;
        }
    },
    watch: {
        id: function () {
            this.load();
        },
        addressableID: function() {
            if (this.addressableID) {
                this.formFields.addressable_id.val = this.addressableID;
            }
        },
        currentView: function () {
            if (this.currentView == 'search') {
                window.location.reload()
            }
        }
    }
}
</script>

<style scoped>

</style>